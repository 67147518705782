<template>
    <div class="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50">
        <div class="mt-1 flex size-11 flex-none items-start rounded-lg">
            <img
                v-if="federalState.attributes.Flagge"
                class="w-10 rounded-sm"
                :src="media">
        </div>
        <div>
            <NuxtLink
                :to="localePath('/bundeslaender/' + url)"
                class="font-semibold text-gray-900"
                @click="emits('close')">
                {{ federalState.attributes?.Name }}
                <span class="absolute inset-0" />
            </NuxtLink>
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    federalState: {
        type: Object,
        default: () => ({})
    }
})

const emits = defineEmits(['close'])

const {public: publicConfig} = useRuntimeConfig()

const url = computed(() => {
    const Seite = props.federalState?.attributes?.Seite?.data?.attributes
    let url = Seite?.url
    if (Seite?.Weiterleiten && Seite?.Unterseiten?.data?.length > 0) {
        url += '/' + Seite.Unterseiten.data[0].attributes.url
    }
    return url
})

const media = ref(null)
await useAsyncData(`${props.federalState?.attributes?.Flagge?.data[0]?.attributes?.url}`, () => useStrapiMedia(props.federalState?.attributes?.Flagge?.data[0]?.attributes?.url ?? ''), {
    // this will not refetch if the key exists in the payload
    getCachedData: key => nuxtApp.payload.static[key] ?? nuxtApp.payload.data[key]
}).then(response => {
    const mediaUrl = response.data?.value ?? null
    media.value = mediaUrl.replace(publicConfig.strapiUrl, publicConfig.publicStrapiUrl)
})
</script>
